import { Component } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'animations';
  camera: any;
  scene: any;
  controls: any;
  swingAudio: any;
  constructor() {

  }

  ngOnInit() {
    //Canvas
    const canvas = document.querySelector('.webgl') as HTMLCanvasElement;

    this.swingAudio = new Audio('assets/sounds/swing.mp3')

    //Scene Initialisation  
    this.scene = new THREE.Scene();
    const cubeTextureLoader = new THREE.CubeTextureLoader()
    
    this.scene.background = new THREE.Color( 0xd9d9d9 );

    const fog = new THREE.Fog('#2669b9', 1, 300)
    // this.scene.fog = fog;

    // Texture Loader
    const textureLoader = new THREE.TextureLoader()


    // Loaders and decoders

    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('assets/decoders/draco/')

    const gltfLoader = new GLTFLoader()
    gltfLoader.setDRACOLoader(dracoLoader)

    const Texture = textureLoader.load('assets/model/bakedKinan.jpg')
    Texture.flipY = false
    Texture.encoding = THREE.sRGBEncoding

    const Material = new THREE.MeshBasicMaterial({ map: Texture, side: THREE.DoubleSide, wireframe: false })

    gltfLoader.load(
      'assets/model/KINAN.glb',
      (gltf) => {

        gltf.scene.traverse((o)=>{
          if(o instanceof THREE.Mesh) { 
            o.material = Material;
          }
          
        })
        this.scene.add(gltf.scene)
        gltf.scene.scale.set(5,5,5)
      }
    )


    //Renderer Size
    const sizes = {
      width: innerWidth,
      height: innerHeight
    }

    //Render Initialisation
    const renderer = new THREE.WebGLRenderer({ canvas: canvas, alpha: false })
    // document.body.appendChild( renderer.domElement );
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.outputEncoding = THREE.sRGBEncoding

    //Camera Initialisation
    this.camera = new THREE.PerspectiveCamera(65, sizes.width / sizes.height, 0.1, 1000)
    this.camera.position.set(119.30992150243785,26.837532826184404,37.3382559582341)
    this.scene.add(this.camera)

    // Controls
    this.controls = new OrbitControls(this.camera, canvas)
    this.controls.enableDamping = true;
    this.controls.target.set(-0.5945259157109549,22.69781863017528,9.305388509724331)
    // this.controls.enableZoom = false;
    // this.controls.autoRotate = true;

    //Resize Event
    window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = window.innerWidth
      sizes.height = window.innerHeight

      //Update camera
      this.camera.aspect = sizes.width / sizes.height
      this.camera.updateProjectionMatrix()

      //Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    // Clock
    let clock = new THREE.Clock()
    let oldELapsedTime = 0

    //Animate
    const tick = () => {

      //Clock
      const elapsedTime = clock.getElapsedTime()
      const deltaTime = elapsedTime - oldELapsedTime
      oldELapsedTime = elapsedTime

      this.controls.update()
      renderer.render(this.scene, this.camera)
      window.requestAnimationFrame(tick)
    }
    tick()
  }

}
